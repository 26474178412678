import React from "react"

import Facebook from "../../images/svgs/facebook.svg"
import Instagram from "../../images/svgs/instagram.svg"

const SocialIcons = () => {
  return (
    <div className="flex ">
      <a
        href="https://www.facebook.com/klinkepianoservice/"
        target="_blank"
        className="social-icon__link"
        rel="noopener noreferrer"
      >
        <Facebook className="social-icon" />
      </a>
      <a
        href="https://www.instagram.com/explore/tags/klinkepianoservice/?hl=en    "
        target="_blank"
        className="social-icon__link"
        rel="noopener noreferrer"
      >
        <Instagram className="social-icon" />
      </a>
    </div>
  )
}

export default SocialIcons
