import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Header from "../3_organisms/header"
import Banner from "../1_atoms/banner/banner"
import "../../css/main.css"

const Layout = ({ children, mainClass, image, pageTitle, noTitle, isHome }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Header
          siteTitle={data.site.siteMetadata.title}
          menuLinks={data.site.siteMetadata.menuLinks}
          isHome={isHome}
        />
        <main className={`main relative ${mainClass ? mainClass : undefined} `}>
          <div className="main-wrapper relative w-full mb-8">
            <Banner image={image} pageTitle={pageTitle} noTitle={noTitle} />
            <div className="content-layout">{children}</div>
          </div>
        </main>
        <footer />
      </>
    )}
  />
)

export default Layout
