import React from "react"
import { Link } from "gatsby"

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  toggleMenu() {
    const header = document.getElementById("header")
    header.classList.toggle("active")
    this.setState({
      menuOpen: !this.state.menuOpen,
    })
  }

  render() {
    const { menuLinks } = this.props
    const { menuOpen } = this.state

    return (
      <>
        <div className="mobile-header">
          <button
            aria-label="mobile menu button"
            id="menu-icon"
            className={`menu-icon ${menuOpen ? "open" : ""}`}
            onClick={() => this.toggleMenu()}
          >
            <div>
              <span className="bar top" />
              <span className="bar middle" />
              <span className="bar bottom" />
            </div>
            <div className="menu-icon__title">Klinke Piano Service</div>
          </button>
        </div>
        <nav
          id="main-menu"
          role="menu"
          className={`main-nav ${menuOpen ? "open" : ""}`}
          aria-expanded="false"
        >
          {menuLinks.map(link => (
            <Link
              key={link.name}
              to={link.link}
              className="main-nav-link hover:text-mBlue relative"
              role="menuitem"
              activeClassName="active"
            >
              {link.name}
            </Link>
          ))}
          <Link
            className="main-nav-link contact btn mt-2"
            role="menuitem"
            activeClassName="active"
            to="contact"
          >
            Contact
          </Link>
        </nav>
      </>
    )
  }
}

export default Menu
