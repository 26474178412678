import React from "react"

const Banner = ({ image, pageTitle }) => (
  <div className="banner my-8 relative">
    <h1 className="page-title py-2 text-mBlue">{pageTitle}</h1>
    {image}
  </div>
)

export default Banner
