import { Link } from "gatsby"
import React from "react"

import Menu from "../2_molecules/menu"
import SocialIcons from "../2_molecules/SocialIcons"
import Logo from "../../images/svgs/logo.svg"

const Header = ({ menuLinks, isHome }) => (
  <header
    id="header"
    className="header max-w-6xl mx-auto py-2 px-4 md:px-8 py-8 flex flex-col items-end bg-mBlue"
  >
    <div className="logo">
      <Link to="/">
        <Logo />
      </Link>
    </div>
    <div className="home-title mt-4 p-4 pb-0 text-lg uppercase text-right text-white font-bold">
      Piano tuning and repair serving the Philadelphia/South Jersey Area
    </div>
    <Menu menuLinks={menuLinks} />
    <SocialIcons />
  </header>
)

export default Header
